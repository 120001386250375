<template>
  <div>
    <type-risk-place-form
      :is-toggle-sidebar.sync="isToggleSidebar"
      :form-type="formType"
      :data-edit="dataEdit"
      @refetch-data="refetchData"
      @error-data="showError"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrando</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>registros</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="query"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
              />
              <b-button
                variant="primary"
                @click="openForm('new')"
              >
                <span class="text-nowrap">Agregar</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="items"
        responsive
        :fields="fields"
        primary-key="id"
        :sort-by.sync="sortBy"
        :busy="isBusy"
        show-empty
        empty-text="No se encontraron registros"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner
              variant="primary"
              class="align-middle"
            />
            <strong class="ml-1">Cargando...</strong>
          </div>
        </template>

        <template #cell(name)="data">
          <div class="d-flex align-items-center">
            <div>
              <div class="font-small-3 font-weight-bolder">
                {{ data.item.name }}
              </div>
              <div class="font-small-2 text-muted">
                {{ data.item.description }}
              </div>
            </div>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(acciones)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="getEdit(data)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>

            <b-dropdown-item @click="getDelete(data)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Eliminar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando {{ from }} a {{ to }} de {{ of }} registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="page"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BDropdown, BDropdownItem, BPagination, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { showToast, showError } from '@/helpers'
import TypeRiskPlaceForm from './TypeRiskPlaceForm.vue'

export default {
  components: {
    TypeRiskPlaceForm,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    BSpinner,
  },
  data: () => ({
    isToggleSidebar: false,
    dataEdit: {},
    formType: '',
    page: 1,
    perPage: 10,
    totalRows: 0,
    totalItems: 0,
    totalPage: 0,
    from: 0,
    to: 0,
    of: 0,
    pageOptions: [10, 20, 50],
    sortBy: null,
    sortDesc: false,
    sortDirection: 'asc',
    query: '',
    items: [],
    fields: [
      { key: 'idTypeRiskPlace', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
      { key: 'name', sortable: true },
      { key: 'acciones', tdClass: ['w100', 'text-center'], thClass: 'text-center' },
    ],
    isBusy: false,
  }),
  watch: {
    perPage: {
      handler() {
        this.listItems()
      },
    },
    page: {
      handler() {
        this.listItems()
      },
    },
    query: {
      handler() {
        this.listItems()
      },
    },
  },
  mounted() {
    this.listItems()
  },
  methods: {
    async listItems() {
      this.isBusy = true
      await store
        .dispatch('setting/TYPE_RISK_PLACE_FIND_ALL', {
          limit: this.perPage,
          query: this.query,
          page: this.page,
          sortBy: 'idTypeRiskPlace%7CDESC',
          type: 'grilla',
        })
        .then(response => {
          this.items = response.items
          this.totalRows = response.totalRows
          this.totalItems = response.totalItems
          this.totalPage = response.totalPage
          this.from = this.perPage * (this.page - 1) + (this.totalRows ? 1 : 0)
          this.to = this.perPage * (this.page - 1) + this.totalRows
          this.of = this.totalItems
        })
        .catch(error => {
          this.$bvToast.toast(error, {
            title: 'Ha ocurrido un error',
            variant: 'danger',
            solid: true,
          })
        })
      this.isBusy = false
    },
    openForm(type) {
      this.formType = type
      this.isToggleSidebar = true
    },
    getEdit(data) {
      this.formType = 'edit'
      this.isToggleSidebar = true
      this.dataEdit = data.item
    },
    getDelete(data) {
      this.$swal({
        title: 'Está seguro?',
        text: 'Va eliminar un registro!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store
            .dispatch('setting/TYPE_RISK_PLACE_DELETE', {
              id: data.item.idTypeRiskplace,
            })
            .then(response => {
              this.listItems()
              this.$bvToast.toast('Ha Eliminado correctamente el registro.', {
                title: 'Operación Exitosa',
                variant: 'success',
                solid: true,
              })
              return response
            })
            .catch(error => {
              this.$bvToast.toast(error, {
                title: 'Ha ocurrido un error',
                variant: 'danger',
                solid: true,
              })
            })
        }
      })
    },
    refetchData(response) {
      let message = 'El proceso se ha ejecutado satisfactoriamente.'
      if (response.message) {
        message = response.message
      }
      this.showToast('Operación Extitosa', message, 'success')
      this.listItems()
    },
    showToast,
    showError,
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
.w100{
  width: 100px !important;
}
</style>
